import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Loader from '../../../components/other/Loader';
import { baseAPIURL } from '../../../store/api/Access';
import { Container, Typography, Paper, List, ListItem, ListItemText, Alert } from '@mui/material';

const TeamAttachments = () => {
    const { teamId } = useParams();
    const [attachments, setAttachments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        setLoading(true);

        firebase.auth().currentUser.getIdToken().then(function(idToken) {
            let config = {
                method: 'get',
                url: `${baseAPIURL}/teams/${teamId}/attachments`,
                headers: { 'Authorization': idToken }
            };

            axios.request(config)
                .then(response => {
                    setAttachments(response.data);
                    setLoading(false);
                })
                .catch(err => {
                    console.error('Error fetching attachments:', err);
                    setError('Failed to load attachments');
                    setLoading(false);
                });
        });
    }, [teamId]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Container maxWidth="md">
            <Paper style={{ padding: 16, marginTop: 16 }}>
                <Typography variant="h4">Team Attachments</Typography>
                {attachments.length > 0 ? (
                    <List>
                        {attachments.map(attachment => (
                            <ListItem 
                                key={attachment.id} 
                                button 
                                component={Link} 
                                to={`/team/${teamId}/attachments/${attachment.id}`}
                            >
                                <ListItemText primary={attachment.name} secondary={attachment.description} />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography variant="body1">No attachments found for this team.</Typography>
                )}
            </Paper>
        </Container>
    );
};

export default TeamAttachments;
