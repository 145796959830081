import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loader from '../../../components/other/Loader';
import { baseAPIURL } from '../../../store/api/Access';
import { Container, Typography, Paper, Button, Box, Alert } from '@mui/material';

const DocumentDetails = () => {
    const { teamId, documentId } = useParams();
    const [document, setDocument] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        setLoading(true);
        setError('');
    
        firebase.auth().currentUser.getIdToken().then(function(idToken) {
            // Fetch Document Details
            axios.get(`${baseAPIURL}/documents/${documentId}`, {
                headers: { 'Authorization': idToken }
            })
            .then(response => {
                setDocument(response.data);
                // Here, also check if the user has permission to edit the document
                // For example, setEditMode( userHasEditPermission );
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching document details:', err);
                setError('Failed to load document details');
                setLoading(false);
            });
    
            // Optionally, fetch user's permissions if they are not part of the document details response
            // axios.get(`${baseAPIURL}/teams/${teamId}/permissions`, { /* ... */ })
        });
    }, [teamId, documentId]);
    

    // TODO: Function to handle saving edited document
    const handleSave = async () => {
        setLoading(true);
        setError('');
    
        try {
            const idToken = await firebase.auth().currentUser.getIdToken();
            await axios.put(`${baseAPIURL}/documents/${documentId}`, {
                title: document.title,  // Assuming title is editable, otherwise remove this line
                content: document.content
            }, {
                headers: { 'Authorization': idToken }
            });
    
            // Optionally, you can refetch the document details here to ensure the UI is synced with the database
            // Or just rely on the local state, which is already updated
    
            setEditMode(false);  // Exit edit mode after saving
        } catch (err) {
            console.error('Error saving document:', err);
            setError('Failed to save document. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Container maxWidth="md">
            <Paper style={{ padding: 16, marginTop: 16 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4">{document?.title}</Typography>
                    {/* Check for editing permissions before showing the button */}
                    <Button onClick={() => setEditMode(!editMode)}>
                        {editMode ? 'View' : 'Edit'}
                    </Button>
                </Box>
                {editMode ? (
                    <>
                        <ReactQuill theme="snow" value={document?.content} onChange={(value) => setDocument({...document, content: value})} />
                        <Button variant="contained" color="primary" onClick={handleSave} style={{ marginTop: 16 }}>
                            Save Changes
                        </Button>
                    </>
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: document?.content }} />
                )}
            </Paper>
        </Container>
    );
};

export default DocumentDetails;
