import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import { Container, List, ListItem, ListItemText, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { baseAPIURL } from '../../../../store/api/Access'; // Adjust according to your project's structure

const TeamSettings = () => {
    const { teamId } = useParams();
    const [users, setUsers] = useState([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [newUserEmail, setNewUserEmail] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchUsers();
    }, [teamId]);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const idToken = await firebase.auth().currentUser.getIdToken();
            const response = await axios.get(`${baseAPIURL}/team/${teamId}/users`, {
                headers: { 'Authorization': `Bearer ${idToken}` },
            });
            setUsers(response.data);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching team users:', err);
            setError('Failed to load team users.');
            setLoading(false);
        }
    };

    const handleAddUser = async () => {
        // Implement user addition logic
    };

    const handleRemoveUser = async (userId) => {
        // Implement user removal logic
    };

    const handleOpenAddDialog = () => setOpenAddDialog(true);
    const handleCloseAddDialog = () => setOpenAddDialog(false);
    const handleChangeNewUserEmail = (e) => setNewUserEmail(e.target.value);

    // Render loading, error, or user list
    return (
        <Container>
            <Button startIcon={<AddIcon />} onClick={handleOpenAddDialog}>Add User</Button>
            <List>
                {users.map(user => (
                    <ListItem key={user.id} secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveUser(user.id)}>
                            <DeleteIcon />
                        </IconButton>
                    }>
                        <ListItemText primary={user.name} secondary={user.email} />
                    </ListItem>
                ))}
            </List>

            {/* Add User Dialog */}
            <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
                <DialogTitle>Add a New Team User</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="User Email"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={newUserEmail}
                        onChange={handleChangeNewUserEmail}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDialog}>Cancel</Button>
                    <Button onClick={handleAddUser}>Add</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default TeamSettings;
