import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import firebase from 'firebase/compat/app'
import Logo from '../../assets/Logo.png';
import { NavLink,useLocation,useParams   } from 'react-router-dom'


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import AppsIcon from '@mui/icons-material/Apps';

import MenuIcon from '@mui/icons-material/Menu';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';


import DashboardIcon  from '@mui/icons-material/Dashboard';
import WorkOutlineIcon   from '@mui/icons-material/WorkOutline';
import DescriptionIcon   from '@mui/icons-material/Description';
import InventoryIcon   from '@mui/icons-material/Inventory';
import LocalShippingIcon   from '@mui/icons-material/LocalShipping';
import ShoppingBagIcon   from '@mui/icons-material/ShoppingBag';
import PeopleIcon   from '@mui/icons-material/People';
import AttachFileIcon   from '@mui/icons-material/AttachFile';
import AccountBalanceIcon    from '@mui/icons-material/AccountBalance';




const drawerWidth = 240;
const MaxViewWidth = 500;

const Header = () => {    
    
    const [isWideView, setIsWideView] = useState(false);
    const [open, setOpen] = useState(false);
    const { pathname } = useLocation();
    
  const location = useLocation();

  const pathSegments = location.pathname.split('/'); // Split the URL path
  const teamId = pathSegments[2] ?  pathSegments[2] : null; // Get the ID part

  const isProjectRoute = teamId && pathSegments[1] && pathSegments[1] == "team" //location.pathname.startsWith(`/team/${teamId}`) ;
    console.log(pathSegments[1])
    useEffect(() => {
        const handleResize = () => {
            setIsWideView(window.innerWidth < drawerWidth+MaxViewWidth);
            setOpen(false)
        };

        // Add event listener to update the state on window resize
        window.addEventListener('resize', handleResize);

        // Call handleResize initially to set the initial state
        handleResize();

        // Clean up the event listener on component unmount
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const highlightClass = {
    fontWeight:"700 !important",
    "& *": {
      fontWeight:"700 !important",

    }
  };
  
  const shouldHighlightMenuItemWildCard = (menuItemPath) => {
    return pathname.startsWith(menuItemPath);
  };


  const shouldHighlightMenuItem = (menuItemPath) => {
    return pathname === menuItemPath;
  };
  
    
   

    return (
        < >

      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          
        {isWideView && isProjectRoute ?
                    <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>:null
                    }
          <Box  component={NavLink} to="/" >
          <img src={Logo} alt="logo" className='HeaderLogo' />
          </Box>

          <Box  sx={{ flexGrow: 1 }}/>
                    

          {!isWideView ?
          <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        component={NavLink} to="/account" 
                    >
                        <PersonIcon />
                    </IconButton>
                    :null}



        </Toolbar>
      </AppBar>


{isProjectRoute && (open || !isWideView) ?
            <Drawer
                    anchor="left"

        open={open}
        variant={!isWideView ? "permanent": "persistent"}
        sx={{
          marginRight:isWideView ? "-200px": 0,
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { 
            width: drawerWidth, boxSizing: 'border-box' ,
            background: "transparent;",
            borderRight: "none"
          },

          

        }}
      >
        <Toolbar />
        <Box sx={ { overflow: 'auto',background: !isWideView ? "transparent;":"#fff;",borderRight: !isWideView ? "1px solid #094663 !important;": "none",}}>
          <List>
              <ListItem
                sx={shouldHighlightMenuItem(`/team/${teamId}`) ? highlightClass : {}} 
                onClick={handleDrawerClose} component={NavLink} to={`/team/${teamId}`} key="Overview" disablePadding>
                <ListItemButton className='TeamMenuListItem'>
                  <ListItemIcon>
                    <DashboardIcon  />
                  </ListItemIcon>
                  <ListItemText primary="Overview" />
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={shouldHighlightMenuItemWildCard(`/team/${teamId}/projects`) ? highlightClass : {}} 
                onClick={handleDrawerClose} component={NavLink} to={`/team/${teamId}/projects`} key="Projects" disablePadding>
                <ListItemButton className='TeamMenuListItem'>
                  <ListItemIcon>
                    <WorkOutlineIcon  />
                  </ListItemIcon>
                  <ListItemText primary="Projects" />
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={shouldHighlightMenuItemWildCard(`/team/${teamId}/documents`) ? highlightClass : {}}  
                onClick={handleDrawerClose} component={NavLink} to={`/team/${teamId}/documents`} key="Alerts" disablePadding>
                <ListItemButton className='TeamMenuListItem'>
                  <ListItemIcon>
                    <DescriptionIcon  />
                  </ListItemIcon>
                  <ListItemText primary="Documents" />
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={shouldHighlightMenuItemWildCard(`/team/${teamId}/inventory`) ? highlightClass : {}}  
                onClick={handleDrawerClose} component={NavLink} to={`/team/${teamId}/inventory`} key="Map" disablePadding>
                <ListItemButton className='TeamMenuListItem'>
                  <ListItemIcon>
                    <InventoryIcon  />
                  </ListItemIcon>
                  <ListItemText primary="Inventory" />
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={shouldHighlightMenuItemWildCard(`/team/${teamId}/products`) ? highlightClass : {}}  
                onClick={handleDrawerClose} component={NavLink} to={`/team/${teamId}/products`} key="Account" disablePadding>
                <ListItemButton className='TeamMenuListItem'>
                  <ListItemIcon>
                    <ShoppingBagIcon  />
                  </ListItemIcon>
                  <ListItemText primary="Products" />
                </ListItemButton>
              </ListItem>
              
              <ListItem
                sx={shouldHighlightMenuItemWildCard(`/team/${teamId}/suppliers`) ? highlightClass : {}}  
                onClick={handleDrawerClose} component={NavLink} to={`/team/${teamId}/suppliers`} key="Get Devices" disablePadding>
                <ListItemButton className='TeamMenuListItem'>
                  <ListItemIcon>
                    <LocalShippingIcon  />
                  </ListItemIcon>
                  <ListItemText primary="Suppliers" />
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={shouldHighlightMenuItemWildCard(`/team/${teamId}/customers`) ? highlightClass : {}}  
                onClick={handleDrawerClose} component={NavLink} to={`/team/${teamId}/customers`} key="Account" disablePadding>
                <ListItemButton className='TeamMenuListItem'>
                  <ListItemIcon>
                    <PeopleIcon  />
                  </ListItemIcon>
                  <ListItemText primary="Customers" />
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={shouldHighlightMenuItemWildCard(`/team/${teamId}/attachments`) ? highlightClass : {}}  
                onClick={handleDrawerClose} component={NavLink} to={`/team/${teamId}/attachments`} key="Account" disablePadding>
                <ListItemButton className='TeamMenuListItem'>
                  <ListItemIcon>
                    <AttachFileIcon  />
                  </ListItemIcon>
                  <ListItemText primary="Attachments" />
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={shouldHighlightMenuItemWildCard(`/team/${teamId}/accounting`) ? highlightClass : {}}  
                onClick={handleDrawerClose} component={NavLink} to={`/team/${teamId}/accounting`} key="Account" disablePadding>
                <ListItemButton className='TeamMenuListItem'>
                  <ListItemIcon>
                    <AccountBalanceIcon />
                  </ListItemIcon>
                  <ListItemText primary="Accounting" />
                </ListItemButton>
              </ListItem>
          </List>

        </Box>

       
      </Drawer>
      :null}


        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser
    }
}

export default connect(mapStateToProps)(Header)


