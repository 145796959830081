import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Loader from '../../../components/other/Loader';
import { baseAPIURL } from '../../../store/api/Access';
import { Container, Typography, Paper, TextField, Button, Box, CircularProgress, Alert } from '@mui/material';

const NewCustomer = () => {
    const { teamId } = useParams();
    const navigate = useNavigate();
    const [customerName, setCustomerName] = useState('');
    const [contactInfo, setContactInfo] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const idToken = await firebase.auth().currentUser.getIdToken();
            await axios.post(`${baseAPIURL}/teams/${teamId}/customers`, {
                name: customerName,
                contactInfo: contactInfo
            }, {
                headers: { 'Authorization': idToken }
            });
            navigate(`/team/${teamId}/customers`);
        } catch (err) {
            console.error('Error creating customer:', err);
            setError('Failed to create customer. Please try again.');
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="sm">
            <Paper style={{ padding: 16, marginTop: 16 }}>
                <Typography variant="h6">Add New Customer</Typography>
                {error && <Alert severity="error" style={{ marginBottom: 16 }}>{error}</Alert>}
                <form onSubmit={handleSubmit}>
                    <Box marginY={2}>
                        <TextField
                            fullWidth
                            label="Customer Name"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                            required
                        />
                    </Box>
                    <Box marginY={2}>
                        <TextField
                            fullWidth
                            label="Contact Info"
                            value={contactInfo}
                            onChange={(e) => setContactInfo(e.target.value)}
                            multiline
                            rows={4}
                        />
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Add Customer'}
                    </Button>
                </form>
            </Paper>
            {loading && <Loader />}
        </Container>
    );
};

export default NewCustomer;
