import React, {Component,useState  } from 'react'
import { NavLink,useParams  } from 'react-router-dom'
import {baseAPIURL} from "../store/api/Access"
import  { useEffect  } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import Card from '@mui/material/Card';
import {TextField,Button,Box} from '@mui/material';
import CardContent from '@mui/material/CardContent';


import firebase from 'firebase/compat/app'


const Index = ({user,}) => {


    
    const [values, setValues] = React.useState({});
    const [email, setEmail] = React.useState({});
    const [password, setPassword] = React.useState({});
    const [{loading,error}, setStatus] = React.useState({loading:true});
  
    let { id } = useParams();


    const handleSave = () => {
        const user = firebase.auth().currentUser;

        user.updateProfile({
            //displayName: "Jane Q. User",
        }).then(() => {
            // Update successful
            // ...
        }).catch((error) => {
            // An error occurred
            // ...
        }); 
        
        
        user.updateEmail({
            //displayName: "Jane Q. User",
        }).then(() => {
            // Update successful
            // ...
        }).catch((error) => {
            // An error occurred
            // ...
        }); 
        
        user.updatePassword({
            //displayName: "Jane Q. User",
        }).then(() => {
            // Update successful
            // ...
        }).catch((error) => {
            // An error occurred
            // ...
        }); 
        
    };
  
  
    const handleChangeValue = (event) => {

        setValues(event.target.value)
    }
  
  
  
    const handleChangeEmail = (event) => {

        const name = event.target.name;
        const value = event.target.value;

        setValues({...values, name:value})
    }
  
  
  
    const handleChangePassword = (event) => {

        const name = event.target.name;
        const value = event.target.value;

        setValues({...values, name:value})
    }
  
  


    
    useEffect(() => {
      //LoadData();
    }, []);
/*
firebase.auth().updateCurrentUser

    console.log(user)
    console.log(user.providerData)
    console.log(user.tenantId) // null
    console.log(user.uid)
    console.log(user.metadata)
    console.log(user.email)
    console.log(user.displayName)
    console.log(user.providerId)
*/


    return (
        <main style={{padding:10}}>
          
            <Card fullWidth sx={{margin: 1 }}>
                <CardContent>
                    <TextField name="displayName" fullWidth label="Name" variant="outlined" onChange={handleChangeValue}  value={"displayName" in values ? values.displayName : user.displayName}/>
                    <br/><br/>
                    <TextField name="email" fullWidth label="Email" variant="outlined" onChange={handleChangeEmail}  value={"email" in values ? values.email : user.email}/>
                    <br/><br/>
                    <TextField name="password" fullWidth label="password" variant="outlined" onChange={handleChangePassword}  value={"password" in values ? values.password : ""}/>

                    <br/><br/>

                    <Box sx={{display: "flex"}}>
                        <Button onClick={handleSave}   variant="contained">Save</Button>
                        <Box  sx={{flexGrow: 1}}/>
                        <Button  component={NavLink} to="/logout" variant="outlined">Logout</Button>
                    </Box>

                </CardContent>
            </Card>

        </main>
    );
}



const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser,
    }
}

export default compose(
    connect(mapStateToProps)
)(Index);