import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { NavLink  } from 'react-router-dom'
import firebase from 'firebase/compat/app'
import { baseAPIURL } from '../store/api/Access';
import { Grid, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import {  AppBar, Toolbar, TextField, Button } from '@mui/material';

import Loader from '../components/other/Loader';

const HomePage = () => {
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredTeams, setFilteredTeams] = useState([]);
    const navigate = useNavigate();


    
    const LoadData = () => {
        firebase.auth().currentUser.getIdToken().then(function(idToken) {
          
          let configActivity = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${baseAPIURL}/teams`,
            headers: { 
              'Authorization': idToken, 
              'Content-Type': 'application/json'
            },
            data : JSON.stringify({})
          };
          
          axios.request(configActivity)
            .then(response => {
                setTeams(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching teams', error);
                setLoading(false);
            });
      });
  };


    useEffect(() => {

        LoadData()
        /*axios.get(`${baseAPIURL}/teams`)
            .then(response => {
                setTeams(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching teams', error);
                setLoading(false);
            });*/
    }, []);


    
    const handleFilterChange = (event) => {
        const filterValue = event.target.value.toLowerCase();
        const filtered = teams.filter(team => team.name.toLowerCase().includes(filterValue));
        setFilteredTeams(filtered);
    };

    const handleAddTeam = () => {
        navigate('/new-team');
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <main style={{padding:10}}>
            
            <AppBar position="static">
                <Toolbar>
                    {/*<TextField 
                        label="Filter Teams" 
                        variant="outlined" 
                        style={{ marginRight: 16, backgroundColor: 'white' }} 
                        onChange={handleFilterChange} 
                    />*/}
                    <Button color="inherit" onClick={handleAddTeam}>Add Team</Button>
                </Toolbar>
            </AppBar>



            {teams.length > 0 ? (
                
                <Grid container spacing={2}>
                    {teams.map(team => (
                        <Grid item xs={12} sm={6} md={4} key={team.id}  component={NavLink} to={`/team/${team.team_id}`}>
                            <Card>
                                <CardActionArea>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            {team.name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {team.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Typography variant="h6">
                    No teams found. <a href="/new-team">Add a team</a>
                </Typography>
            )}
        </main>
    );
};

export default HomePage;



/*
import React, {Component,useState  } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import firebase from 'firebase/compat/app'
import { NavLink,useNavigate  } from 'react-router-dom'

import {Table,Box} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {baseAPIURL} from "../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'


import TimeAgo from 'react-timeago'

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
*/