import React, { useEffect, useState } from 'react';
import { useParams,Link } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app'
import Loader from '../../components/other/Loader';
import Page404 from '../Page404';

import { baseAPIURL } from '../../store/api/Access';
import { Container, Button,Box,Typography, CircularProgress, Alert, Paper } from '@mui/material';

import {  List, ListItem } from '@mui/material';


const TeamPage = () => {
    const { teamId } = useParams();
    const [team, setTeam] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        setLoading(true);


        firebase.auth().currentUser.getIdToken().then(function(idToken) {
          
            let configActivity = {
              method: 'get',
              maxBodyLength: Infinity,
              url: `${baseAPIURL}/teams/${teamId}`,
              headers: { 
                'Authorization': idToken, 
              }
            };

            
            axios.request(configActivity)
            .then(response => {
                setTeam(response.data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching team details:', err);
                setError('Failed to load team details');
                setLoading(false);
            });
        })
    }, [teamId]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    if (!team) {
        return <Page404 />;
    }










    
    const members = [
        { name: "Alice", role: "Developer" },
        { name: "Bob", role: "Designer" },
    ];

    const recentActivities = [
        "Completed task 'Design Homepage'",
        "Updated project 'Website Redesign'",
    ];

    const projects = [
        { name: "Project Alpha", status: "In Progress" },
        { name: "Project Beta", status: "Completed" },
    ];

    const renderTeamMembers = () => (
        <List>
            {members.map((member, index) => (
                <ListItem key={index}>{`${member.name} - ${member.role}`}</ListItem>
            ))}
        </List>
    );

    const renderRecentActivities = () => (
        <List>
            {recentActivities.map((activity, index) => (
                <ListItem key={index}>{activity}</ListItem>
            ))}
        </List>
    );

    const renderProjects = () => (
        <List>
            {projects.map((project, index) => (
                <ListItem key={index}>{`${project.name} - ${project.status}`}</ListItem>
            ))}
        </List>
    );




    return (
        <Paper style={{ padding: 16, margin: 16}}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" component="span">
                {team.name}
            </Typography>
            <Button variant="contained" color="primary" component={Link} to={`/team/${teamId}/settings`}>
                Settings
            </Button>
        </Box>
        <Typography variant="body1" style={{ marginTop: 16 }}>
            {team.description}
        </Typography>
        {/* Additional team details here */}




        
        <Paper className='test' elevation={3} style={{ margin: '16px 0', padding: '16px' }}>
                <Typography variant="h6">Team Members</Typography>
                {renderTeamMembers()}
            </Paper>

            <Paper className='test' elevation={3} style={{ margin: '16px 0', padding: '16px' }}>
                <Typography variant="h6">Recent Activities</Typography>
                {renderRecentActivities()}
            </Paper>

            <Paper className='test' elevation={3} style={{ margin: '16px 0', padding: '16px' }}>
                <Typography variant="h6">Projects</Typography>
                {renderProjects()}
            </Paper>
    </Paper>
    );
};

export default TeamPage;
