import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import Loader from '../../components/other/Loader';

import { baseAPIURL } from '../../store/api/Access';
import { TextField,Dialog, Button, Typography, Container, Paper, Box, CircularProgress, Alert } from '@mui/material';

const NewTeam = () => {
    const [teamName, setTeamName] = useState('');
    const [teamDescription, setTeamDescription] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const validate = () => {
        let tempErrors = {};
        tempErrors.teamName = teamName ? "" : "Team name is required.";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true);
            setError('');
            try {

                firebase.auth().currentUser.getIdToken().then(function(idToken) {
          
                    let configActivity = {
                      method: 'post',
                      maxBodyLength: Infinity,
                      url: `${baseAPIURL}/teams`,
                      headers: { 
                        'Authorization': idToken, 
                        'Content-Type': 'application/json'
                      },
                      data : JSON.stringify({
                        name: teamName,
                        description: teamDescription,
                        status:"active"
                      })
                    };

                    
                    axios.request(configActivity)
                    .then(response => {
                        
                        navigate('/');
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error creating team:', error);
                        setError('Failed to create team. Please try again.');
                        setLoading(false);
                    });
                    

                })
                /*await axios.post(`${baseAPIURL}/teams`, {
                    name: teamName,
                    description: teamDescription
                });*/
            } catch (error) {
                console.error('Error creating team:', error);
                setError('Failed to create team. Please try again.');
                setLoading(false);
            }
        }
    };

    return (
        <Container maxWidth="sm">
            <Paper style={{ padding: 16 }}>
                <Typography variant="h6" gutterBottom>
                    Create New Team
                </Typography>
                {error && <Alert severity="error" style={{ marginBottom: 16 }}>{error}</Alert>}
                <form onSubmit={handleSubmit}>
                    <Box marginY={2}>
                        <TextField
                            fullWidth
                            label="Team Name"
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                            required
                            error={!!errors.teamName}
                            helperText={errors.teamName}
                        />
                    </Box>
                    <Box marginY={2}>
                        <TextField
                            fullWidth
                            label="Team Description"
                            value={teamDescription}
                            onChange={(e) => setTeamDescription(e.target.value)}
                            multiline
                            rows={4}
                        />
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                        style={{ position: 'relative' }}
                    >
                        Create Team
                    </Button>
                </form>
            </Paper>

            
            <Dialog open={ loading} sx={{"& .MuiPaper-root":{background: "transparent;",boxShadow: "none"}}}>
                <Loader />
            </Dialog>
        </Container>
    );
};

export default NewTeam;
