import React from 'react'
import { Container, Typography, Box, CircularProgress } from '@mui/material';

const View = () => {

    return (
        <Container maxWidth="sm" style={{ textAlign: 'center' }}>
            <Typography variant="h3" gutterBottom>
                Page Under Construction
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                We're working hard to improve our website and we'll ready to launch after.
            </Typography>
        </Container>
        )
    
}
export default View
