import React from 'react'
import Box from '@mui/material/Box';

const View = () => {

    return (
            <Box >

                Doc
            </Box>
        )
    
}
export default View
