import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { NavLink  } from 'react-router-dom'
import firebase from 'firebase/compat/app';
import Loader from '../../../components/other/Loader';
import { baseAPIURL } from '../../../store/api/Access';
import { Container, Typography, Paper, List, ListItem, ListItemText,Button,Box } from '@mui/material';

const TeamDocuments = () => {
    const { teamId } = useParams();
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        setLoading(true);

        firebase.auth().currentUser.getIdToken().then(function(idToken) {
            let config = {
                method: 'get',
                url: `${baseAPIURL}/teams/${teamId}/documents`,
                headers: { 'Authorization': idToken }
            };

            axios.request(config)
                .then(response => {
                    setDocuments(response.data);
                    setLoading(false);
                })
                .catch(err => {
                    console.error('Error fetching documents:', err);
                    setError('Failed to load documents');
                    setLoading(false);
                });
        });
    }, [teamId]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <Typography variant="body1" color="error">{error}</Typography>;
    }

    return (
        <Container maxWidth="md">
            <Paper style={{ padding: 16, marginTop: 16 }}>
                <Box sx={{display:"flex"}}>
                <Typography variant="h4">Team Documents</Typography>
                <Box sx={{flexGrow:1}}/>
                <Button 
                component={NavLink}
                to={`/team/${teamId}/new-document`}
                    variant="contained" 
                    color="primary" 
                    style={{ marginBottom: 16 }}
                >
                    Add New Document
                </Button>
                </Box>

                {documents.length > 0 ? (
                    <List>
                        {documents.map(document => (
                            <ListItem key={document.id} button component={Link} to={`/team/${teamId}/documents/${document.doc_id}`}>
                                <ListItemText primary={document.title} />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography variant="body1">No documents found for this team.</Typography>
                )}
            </Paper>
        </Container>
    );
};

export default TeamDocuments;
