import React, {Component } from 'react'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/Box';
import Logo from '../../assets/Logo.png';



const Loader = (props) => {

    
        return (<div className="loading-container">
        <img src={Logo} alt="Loading" className="rotating-image" />
    </div>)
}

export default (Loader);