import React,{connect} from 'react'
import { Redirect } from 'react-router-dom'
import { getFirebase } from 'react-redux-firebase';
//import {Userlogout} from '../../store/actions/authActions'


const Logout = () => {
    const firebase = getFirebase()
    firebase.auth().signOut().then(()=>{
        window.location.href  = "/";
    })
    return null
}
/*
const mapDispatchToProps = (dispatch) => {
    return {
        Userlogout: () => dispatch(Userlogout())
    }
  }*/

export default /*connect(mapDispatchToProps)*/(Logout)