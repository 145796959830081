import React from 'react';

import { BrowserRouter, Route, Routes  } from 'react-router-dom'
import Box from '@mui/material/Box';

import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Page404 from './views/Page404' 
import Logout from './views/auth/Logout' 

import Home from './views/Home'
import Account from './views/Account'
import UnderConstuctions from './views/UnderConstuctions'




import TeamNew from './views/Team/New'
import TeamHome from './views/Team/TeamHome'



import TeamSettingsView from './views/Team/Settings/View'
import TeamSettingsTeam from './views/Team/Settings/Tabs/Team'
import TeamSettingsUsers from './views/Team/Settings/Tabs/Users'
import TeamSettingsOther from './views/Team/Settings/Tabs/Other'





import TeamProjectsList from './views/Team/Projects/List'
import TeamProjectsNew from './views/Team/Projects/New'
import TeamProjectsItem from './views/Team/Projects/View'

import TeamDocumentsList from './views/Team/Documents/List'
import TeamDocumentsNew from './views/Team/Documents/New'
import TeamDocumentsItem from './views/Team/Documents/Item'


import TeamProductsList from './views/Team/Products/List'
import TeamProductsNew from './views/Team/Products/New'
import TeamProductsItem from './views/Team/Products/Item'


import TeamAttachmentsList from './views/Team/Attachments/List'
import TeamAttachmentsUpload from './views/Team/Attachments/Upload'
import TeamAttachmentsItem from './views/Team/Attachments/Item'

import TeamCustomersList from './views/Team/Customers/List'
import TeamCustomersNew from './views/Team/Customers/New'
import TeamCustomersItem from './views/Team/Customers/Item'

import TeamSuppliersList from './views/Team/Suppliers/List'
import TeamSuppliersNew from './views/Team/Suppliers/New'
import TeamSuppliersItem from './views/Team/Suppliers/Item'


import TeamProjectsTasksList from './views/Team/Projects/Tabs/Tasks/List';
import TeamProjectsTasksView from './views/Team/Projects/Tabs/Tasks/View';

import TeamProjectsOverview from './views/Team/Projects/Tabs/Overview';
import TeamProjectsDocuments from './views/Team/Projects/Tabs/Documents';
import TeamProjectsAttachments from './views/Team/Projects/Tabs/Attachments';
import TeamProjectsCustomer from './views/Team/Projects/Tabs/Customer';
import TeamProjectsBom from './views/Team/Projects/Tabs/Bom';
import TeamProjectsAccounting from './views/Team/Projects/Tabs/Accounting';


function App() {
  return (
    <BrowserRouter>
      <Box className="App" sx={{ "display": 'flex' }}>
          <Header /> 

          <Box className="MainView" sx={{flexGrow: 1}}>
            <Routes >
              <Route path='/' element={<Home/>} />
              <Route path='/logout' element={<Logout/>} />
              
              <Route path='/new-team' element={<TeamNew/>} />

              <Route path='/team/'>
              
                <Route index element={<Home/>} />
                
                  
                <Route path=':teamId'>
                  <Route index element={<TeamHome/>} />


                  <Route path='settings' element={<TeamSettingsView/>} >
                    <Route index element={<TeamSettingsTeam />} />
                    <Route path="team" element={<TeamSettingsUsers/>} />
                    <Route path="other" element={<TeamSettingsOther/>} />
                    <Route path='*'  element={<Page404/>}  status={404}/>

                  </Route>




                  <Route path='new-project' element={<TeamProjectsNew/>} />
                  <Route path='projects' >
                    <Route index element={<TeamProjectsList/>} />
                    <Route path=':projectId' element={<TeamProjectsItem/>} >
                      <Route index element={<TeamProjectsOverview />} />


                      <Route path="tasks" >
                        <Route index element={<TeamProjectsTasksList />} />
                        <Route path=':taskId' element={<TeamProjectsTasksView/>} />
                      </Route>

                      <Route path="documents" element={<TeamProjectsDocuments />} />
                      <Route path="attachments" element={<TeamProjectsAttachments />} />
                      <Route path="accounting" element={<TeamProjectsAccounting />} />
                      <Route path="customer" element={<TeamProjectsCustomer />} />
                      <Route path="bom" element={<TeamProjectsBom />} />
                      
                    </Route>

                  </Route>


                  <Route path='new-products' element={<TeamProductsNew/>} />
                  <Route path='products'>
                    <Route index element={<TeamProductsList/>} />
                    <Route path=':productsId' element={<TeamProductsItem/>} />
                  </Route>



                  <Route path='new-document' element={<TeamDocumentsNew/>} />
                  <Route path='documents'>
                    <Route index element={<TeamDocumentsList/>} />
                    <Route path=':documentId' element={<TeamDocumentsItem/>} />
                  </Route>



                  <Route path='upload' element={<TeamAttachmentsUpload/>} />
                  <Route path='attachments'>
                    <Route index element={<TeamAttachmentsList/>} />
                    <Route path=':attachmentsId' element={<TeamAttachmentsItem/>} />
                  </Route>



                  <Route path='new-customers' element={<TeamCustomersNew/>} />
                  <Route path='customers'>
                    <Route index element={<TeamCustomersList/>} />
                    <Route path=':customerId' element={<TeamCustomersItem/>} />
                  </Route>




                  <Route path='new-suppliers' element={<TeamSuppliersNew/>} />
                  <Route path='suppliers'>
                    <Route index element={<TeamSuppliersList/>} />
                    <Route path=':suppliersId' element={<TeamSuppliersItem/>} />
                  </Route>








                  
                  <Route path='inventory' element={<UnderConstuctions/>} />
                  <Route path='suppliers' element={<UnderConstuctions/>} />
                  <Route path='products' element={<UnderConstuctions/>} />
                  <Route path='accounting' element={<UnderConstuctions/>} />
                </Route>
              </Route>

              <Route path='/account' element={<Account/>} />
                
              <Route path='*'  element={<Page404/>}  status={404}/>
            </Routes >
          </Box>
          
          <Footer />
      </Box>
    </BrowserRouter>
  );
}

export default App;
