import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import { baseAPIURL } from '../../../../../store/api/Access';

const TaskDetails = () => {
    const { teamId, projectId, taskId } = useParams();
    const [task, setTask] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchTask = async () => {
            setLoading(true);
            try {
                const idToken = await firebase.auth().currentUser.getIdToken();
                const response = await axios.get(`${baseAPIURL}/tasks/${taskId}`, {
                    headers: { 'Authorization': idToken },
                });
                setTask(response.data);

                // Here you should include logic to check if the current user has edit permissions
                // This could be part of the response or require another API call
                // For simplicity, assuming it's part of the response for now
                setIsEditable(response.data.canEdit);
            } catch (err) {
                console.error('Error fetching task:', err);
                setError('Failed to load task.');
            } finally {
                setLoading(false);
            }
        };

        fetchTask();
    }, [teamId, projectId, taskId]);

    const handleEdit = (updatedFields) => {
        if (!isEditable) {
            console.warn("User doesn't have permission to edit this task.");
            return;
        }

        setLoading(true);
        firebase.auth().currentUser.getIdToken().then(idToken => {
            axios.put(`${baseAPIURL}/tasks/${taskId}`, updatedFields, {
                headers: { 'Authorization': `Bearer ${idToken}` },
            })
            .then(response => {
                setTask(response.data); // Update the task with the response
                console.log('Task updated successfully');
            })
            .catch(err => {
                console.error('Error updating task:', err);
                setError('Failed to update task.');
            })
            .finally(() => setLoading(false));
        });
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    // Render task details and edit button if editable
    return (
        <div>
            {task ? (
                <>
                    <h2>{task.name}</h2>
                    <p>Description: {task.description}</p>
                    <p>Status: {task.status}</p>
                    <p>Start Date: {task.start_date}</p>
                    <p>Due Date: {task.due_date}</p>
                    <p>Assigned To: {task.assigned_to}</p>
                    {isEditable && <button onClick={() => handleEdit({ /* updatedFields */ })}>Edit Task</button>}
                </>
            ) : (
                <p>Task not found.</p>
            )}
        </div>
    );
};

export default TaskDetails;
